.position-inherit {
  position: inherit!important;
}

.sc-hero-section {
  background: linear-gradient(101.61deg, #6a4bee 3.52%, #a762ec 97.73%)!important;
  padding: 150px 0 200px 0;
}
.btn-primary
{
  background: linear-gradient(101.61deg, #4e26f7 3.52%, #a762ec 97.73%)!important;
  border: none;
}
.bg-gradient {
  background: linear-gradient(101.61deg, #5b46b3 3.52%, #352645 97.73%)!important;
}
.bg-dark1{
  background: linear-gradient(101.61deg, #5b46b3 3.52%, #352645 97.73%)!important;
}
.feature-tab-list-2 li a.active {
  background: linear-gradient(101.61deg, #4e26f7 3.52%, #a762ec 97.73%)!important;
  color: #fff;
  border:none
}
.text-primary {
  color: #8c54ed!important;
}

.bg-dark2{
  background: linear-gradient(101.61deg, #6a4bee 3.52%, #a762ec 97.73%)!important;
}

/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background: linear-gradient(101.61deg, #6a4bee 3.52%, #a762ec 97.73%)!important;
  color: white;
  padding: 15px 22px;
  border: none;
  cursor: pointer;
  opacity: 0.9;
  position: fixed;
  bottom: 23px;
  left: 28px;
  border-radius: 50%;
  z-index: 20;
}

/* The popup chat - hidden by default */
.chat-popup1 {
  display: none;
  position: fixed;
  bottom: 10px;
  left: 25px;
  z-index: 21;
}

/* Add styles to the form container */
.form-container1 {
  max-width: 100px;
  padding: 10px;
  background:linear-gradient(101.61deg, #5b46b3 3.52%, #352645 97.73%)!important;;
  z-index: 201;
  border-radius: 20px;
}

.insta{
  background-color: red;
  border-radius: 50%;
  padding: 10px 10px;
  padding-top: 15px;line-height: 54px;
}
.insta i{
  color:white;
  font-size: 25px;

}
/* Set a style for the submit/send button */
.form-container1 .btn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container1 .cancel {
  border-radius: 50%;
  padding: 5px 10px;
  border: none;
  color: white;
  font-size: 20px;
  margin-top: 20px;
  background: linear-gradient(101.61deg, #6a4bee 3.52%, #a762ec 97.73%)!important;
}

/* Add some hover effects to buttons */
.form-container1 .btn:hover, .open-button:hover {
  opacity: 1;
}